jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	API.bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Add dropdown class to main nav items
	$(".main-nav-li:has('.dropdown-toggle')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link.w-nav-link").click(function(event) {
			if (!$(this).parents(".has-dropdown").hasClass("open")) {
				$(".main-nav-li").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-li.has-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767) {
		$("#wrap").fitVids({
			ignore: "nofit"
		});
	}

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		}
		else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top').click(function() {
		$("html, body").animate({ scrollTop: 0 }, 900);

		return false;
	});

	// Autofocus search field
	$('.search-icon').click(function() {
		$('.large-search-hidden-div').css({
			display: 'flex',
			opacity: '1',
		})
		$('.search-bar-button-wrapper .search-bar-field').focus()
	})

	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 5);
	});
});

/* eslint-disable */
/**
 * ----------------------------------------------------------------------
 * Webflow: Interactions: Init
 */
Webflow.require('ix').init([
	{ "slug": "have-a-promotion-code", "name": "Have a promotion code?", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "inline-block" }], "stepsB": [] }] } },
	{ "slug": "apply", "name": "Apply", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "inline-block" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "block" }], "stepsB": [] }] } },
	{ "slug": "have-another-promotion-code", "name": "Have Another Promotion Code?", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "inline-block" }], "stepsB": [] }] } },
	{ "slug": "apply-2", "name": "Apply 2", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "inline-block" }], "stepsB": [] }] } },
	{ "slug": "open-swatch-selection", "name": "Open Swatch Selection", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "block" }], "stepsB": [] }] } },
	{ "slug": "close-swatches", "name": "Close Swatches", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }] } },
	{ "slug": "select-and-close-swatches", "name": "Select and Close Swatches", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }, { "type": "click", "selector": ".swatches-selection", "stepsA": [{ "display": "block" }], "stepsB": [] }, { "type": "click", "stepsA": [{ "display": "none" }], "stepsB": [] }] } },
	{ "slug": "initial-appearance-none", "name": "Initial Appearance - None", "value": { "style": { "display": "none", "opacity": 0 }, "triggers": [] } },
	{ "slug": "show-search", "name": "Show Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".search-reveal-wrap", "stepsA": [{ "display": "block", "opacity": 0, "height": "0px" }, { "opacity": 1, "height": "auto", "transition": "opacity 500ms ease 0ms, height 250ms ease 0ms" }], "stepsB": [{ "opacity": 0, "height": "0px", "transition": "opacity 250ms ease 0ms, height 500ms ease 0ms" }, { "display": "none" }] }, { "type": "click", "selector": ".search-icon", "stepsA": [{ "opacity": 0, "transition": "opacity 200ms ease 0ms" }, { "display": "none" }], "stepsB": [{ "display": "block", "opacity": 0 }, { "opacity": 0.30000000000000016, "transition": "opacity 200ms ease 0ms" }] }, { "type": "click", "selector": ".close-search-icon", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 0.8, "transition": "opacity 200ms ease 0ms" }], "stepsB": [{ "opacity": 0, "transition": "opacity 200ms ease 0ms" }, { "display": "none" }] }] } },
	{ "slug": "hotspot-popup-position-wrapper", "name": "Hotspot Popup Position Wrapper", "value": { "style": {}, "triggers": [{ "type": "click", "stepsA": [{ "opacity": 0, "transition": "opacity 500ms ease 0ms" }, { "display": "none" }], "stepsB": [{}] }] } },
	{ "slug": "show-popup-1", "name": "Show Popup", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-1", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-sevice-title", "stepsA": [{ "wait": "900ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-2", "name": "Show Popup 2", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-2", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-quality", "stepsA": [{ "wait": "1000ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-3", "name": "Show Popup 3", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-3", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-experience", "stepsA": [{ "wait": "1100ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-4", "name": "Show Popup 4", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-4", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-partnering", "stepsA": [{ "wait": "1200ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-5", "name": "Show Popup 5", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-5", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-teamwork", "stepsA": [{ "wait": "1300ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-6", "name": "Show Popup 6", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-6", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-healthcare", "stepsA": [{ "wait": "1400ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "show-popup-7", "name": "Show Popup 7", "value": { "style": { "scaleX": 0.5, "scaleY": 0.5, "scaleZ": 1 }, "triggers": [{ "type": "click", "selector": ".service-popup-7", "stepsA": [{ "display": "block", "opacity": 0 }, { "opacity": 1, "transition": "opacity 500ms ease 0ms" }], "stepsB": [] }, { "type": "load", "stepsA": [{ "transition": "transform 500ms ease 0ms", "scaleX": 1.5, "scaleY": 1.5, "scaleZ": 1 }, { "transition": "transform 500ms ease 0ms", "scaleX": 1, "scaleY": 1, "scaleZ": 1 }], "stepsB": [] }, { "type": "load", "selector": ".hidden-title-structured-cabling", "stepsA": [{ "wait": "1400ms", "display": "block" }, { "opacity": 1, "transition": "opacity 400ms ease-out-quad 0" }], "stepsB": [] }] } },
	{ "slug": "desktop-dropdown", "name": "Desktop Dropdown", "value": { "style": {}, "triggers": [{ "type": "hover", "selector": ".nav-dropdown-list", "siblings": true, "stepsA": [{ "display": "block" }], "stepsB": [{ "display": "none" }] }] } },
	{ "slug": "desktop-dropdown-list", "name": "Desktop Dropdown List", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [{ "display": "block" }], "stepsB": [{ "wait": 150 }, { "display": "none" }] }] } },
	{ "slug": "on-load-fade-from-top", "name": "On Load - Fade from Top", "value": { "style": { "opacity": 0, "x": "0px", "y": "-100px", "z": "0px" }, "triggers": [{ "type": "load", "stepsA": [{ "opacity": 1, "transition": "transform 500ms ease 0ms, opacity 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "on-load-fade-from-right", "name": "On Load - Fade from Right", "value": { "style": { "opacity": 0, "x": "100px", "y": "0px", "z": "0px" }, "triggers": [{ "type": "load", "stepsA": [{ "opacity": 1, "transition": "transform 500ms ease 0ms, opacity 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "on-scroll-fade-from-left", "name": "On Scroll -  Fade From Left", "value": { "style": { "opacity": 0, "x": "-100px", "y": "0px", "z": "0px" }, "triggers": [{ "type": "scroll", "offsetBot": "5%", "stepsA": [{ "opacity": 1, "transition": "transform 500ms ease 0ms, opacity 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "on-scroll-fade-from-bottom", "name": "On Scroll -  Fade From Bottom", "value": { "style": { "opacity": 0, "x": "0px", "y": "100px", "z": "0px" }, "triggers": [{ "type": "scroll", "offsetBot": "5%", "stepsA": [{ "opacity": 1, "transition": "transform 500ms ease 0ms, opacity 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "show-level-2-company", "name": "Show Level 2 Company", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "fixed-nav-button", "name": "Fixed Nav Button", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-wrapper", "stepsA": [{ "height": "100%" }, { "width": "320px", "transition": "width 550ms ease 0ms" }], "stepsB": [{ "width": "70px", "transition": "width 550ms ease 0ms" }, { "height": "90px" }] }, { "type": "click", "selector": ".fixed-nav-slideout", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }] }, { "type": "click", "selector": ".nav-button-line-1", "preserve3d": true, "stepsA": [{ "transition": "transform 220ms ease 0ms", "x": "0px", "y": "10px", "z": "0px" }, { "transition": "transform 520ms ease 0ms", "rotateX": "0deg", "rotateY": "0deg", "rotateZ": "-45deg" }], "stepsB": [{ "transition": "transform 520ms ease 0ms", "rotateX": "0deg", "rotateY": "0deg", "rotateZ": "0deg" }, { "transition": "transform 220ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }] }, { "type": "click", "selector": ".nav-button-line-2", "stepsA": [{ "wait": 220 }, { "opacity": 0 }], "stepsB": [{ "wait": 520 }, { "opacity": 1 }] }, { "type": "click", "selector": ".nav-button-line-3", "preserve3d": true, "stepsA": [{ "transition": "transform 220ms ease 0ms", "x": "0px", "y": "-10px", "z": "0px" }, { "transition": "transform 520ms ease 0ms", "rotateX": "0deg", "rotateY": "0deg", "rotateZ": "45deg" }], "stepsB": [{ "transition": "transform 520ms ease 0ms", "rotateX": "0deg", "rotateY": "0deg", "rotateZ": "0deg" }, { "transition": "transform 220ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }] }] } },
	{ "slug": "hide-level-2", "name": "Hide Level 2", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2", "preserve3d": true, "stepsA": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] }] } },
	{ "slug": "show-level-2-store", "name": "Show Level 2 Store", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-store", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "hide-level-2-store", "name": "Hide Level 2 Store", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-store", "preserve3d": true, "stepsA": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] }] } },
	{ "slug": "hide-level-2-solutions", "name": "Hide Level 2 Solutions", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-solutions", "preserve3d": true, "stepsA": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] }] } },
	{ "slug": "show-level-2-solutions", "name": "Show Level 2 Solutions", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-solutions", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "hide-level-2-customer-care", "name": "Hide Level 2 Customer Care", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-customer-care", "preserve3d": true, "stepsA": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] }] } },
	{ "slug": "show-level-2-customer-care", "name": "Show Level 2 Customer Care", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-2-customer-care", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "hide-level-3-electronics", "name": "Hide Level 3 Electronics", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-3-electronics", "preserve3d": true, "stepsA": [{ "transition": "transform 500ms ease 0ms", "x": "250px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] }] } },
	{ "slug": "show-level-3-electronics", "name": "Show Level 3 Electronics", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".fixed-nav-slideout-3-electronics", "preserve3d": true, "stepsA": [{ "display": "block", "x": "250px", "y": "0px", "z": "0px" }, { "transition": "transform 500ms ease 0ms", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
	{ "slug": "new-dropdown", "name": "New Dropdown", "value": { "style": {}, "triggers": [{ "type": "dropdown", "stepsA": [{ "opacity": 0, "height": "0px" }, { "opacity": 1, "height": "auto", "transition": "opacity 200ms ease 0ms, height 200ms ease 0ms" }], "stepsB": [{ "height": "0px", "transition": "opacity 100ms ease 0ms, height 100ms ease 0ms" }] }] } },
	{ "slug": "for-two-part-button-toggle", "name": "For Two Part Button Toggle", "value": { "style": {}, "triggers": [] } },
	{ "slug": "open-large-search", "name": "Open Large Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".large-search-hidden-div", "stepsA": [{ "display": "flex", "opacity": 1, "transition": "opacity 450ms ease 0" }], "stepsB": [] }] } },
	{ "slug": "close-large-search", "name": "Close Large Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".large-search-hidden-div", "stepsA": [{ "display": "none", "opacity": 0, "transition": "opacity 350ms ease 0" }], "stepsB": [] }] } },
	{ "slug": "open-search-bar-div", "name": "Open Search Bar Div", "value": { "style": {}, "triggers": [] } },
	{ "slug": "show-hidden-title", "name": "Show Hidden Title", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [], "stepsB": [] }] } },
	{ "slug": "new-interaction", "name": "New Interaction", "value": { "style": {}, "triggers": [{ "type": "scroll", "stepsA": [], "stepsB": [] }] } },
	{ "slug": "back-to-core-action", "name": "Back to Core Action", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".intro-paragraph-services", "stepsA": [{ "display": "block", "opacity": 1, "height": "100px", "transition": "opacity 0.8s ease 0, height 0.8s ease 0" }], "stepsB": [] }, { "type": "click", "selector": ".service-description-1", "stepsA": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "height 0.6s ease 0, opacity 0.6s ease 0" }], "stepsB": [] }, { "type": "click", "selector": ".service-description-2", "stepsA": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "height 0.6s ease 0, opacity 0.6s ease 0" }], "stepsB": [] }, { "type": "click", "selector": ".service-description-3", "stepsA": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "height 0.6s ease 0, opacity 0.6s ease 0" }], "stepsB": [] }, { "type": "click", "selector": ".service-description-4", "stepsA": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "height 0.6s ease 0, opacity 0.6s ease 0" }], "stepsB": [] }, { "type": "click", "selector": ".service-description-5", "stepsA": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "height 0.6s ease 0, opacity 0.6s ease 0" }], "stepsB": [] }] } },
	{ "slug": "button-slide-right", "name": "Button Slide Right", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [{ "transition": "transform 450ms ease-out-quad 0", "x": "5px", "y": "0px", "z": "0px" }], "stepsB": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } }
]);
/* eslint-enable */
